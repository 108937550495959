<template>
  <div class="find-password">
    <div class="content">
      <div>
        <div class="tip">
          <svg-icon icon-class="find-password-tip" class="svg" />
          请选择找回方式
        </div>
        <div class="changeType">
          <div class="mobile-type type">
            <div style="display: flex;justify-content: space-between;width: 100%;padding: 0 32px;">
              <div style="display:flex;">
                <svg-icon icon-class="phone-type" class="svg" />
                <div>
                  <div class="title">通过 手机验证码</div>
                  <div style="font-size: 12px;color: #737d85;">如果你的手机还在使用，请选择此方式</div>
                </div>
              </div>
              <div class="btn"><el-button type="primary" @click="phoneTest">立即验证</el-button></div>
            </div>
          </div>
          <div class="sevoce-type type">
            <div style="display: flex;justify-content: space-between;width: 100%;padding: 0 32px;">
              <div style="display:flex;">
                <svg-icon icon-class="person-service-type" class="svg" />
                <div>
                  <div class="title">通过 联系客服</div>
                  <div style="font-size: 12px;color: #737d85;">如果上述验证方式均无法正常使用，您可以选择人工申诉，预计需要24小时内审核完成</div>
                </div>
              </div>
              <div class="btn"><el-button type="primary" @click="serviceTest">立即验证</el-button></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
  name: 'ChangeFindType',
  data() {
    return {
      accountName: ''
    }
  },
  methods: {
    next() {
      if (this.accountName.length === 0) {
        ElMessage.error('请填写账户名')
      }
    },
    // 手机验证
    phoneTest() {
      this.$router.push('/personal-info/phone-find?account=' + this.$route.query.account)
      // window.open(routerData.href, '_blank')
    },
    // 人工服务验证
    serviceTest() {
      this.$router.push('/personal-info/appeal-find?account=' + this.$route.query.account)
      // window.open(routerData.href, '_blank')
    }
  }
}
</script>

<style lang="less" scoped>
  .find-password{
    height: 100%;
    width: 100%;
    //background: $bg url('bg.png') center no-repeat;
    background: #f5f5f5;
    display: flex;
    justify-content: center;
    .content {
      font-size: 12px;
      color: #313a46;
      padding-top: 72px;
      .tip {
        font-size: 14px;
        height: 14px;
        margin-bottom: 32px;
        .svg {
          margin-right: 10px;
        }
      }
      .changeType {
        .type {
          width: 720px;
          height: 96px;
          margin-bottom: 20px;
          border: 1px solid #cfd3e3;
          border-radius: 2px;
          display: flex;
          justify-content: center;
          align-items: center;
          .title {
            margin-bottom: 8px;
            font-size: 16px;
            color: #313a46;
            font-weight: 500;
          }
          .svg {
            width: 36px;
            height: 36px;
            margin-right: 12px;
          }
          // .btn {
          // }
        }
      }
    }
  }
</style>
